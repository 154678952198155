body {
  background-color: #FFFDD0;
}

.card {
  border-radius: 10px;
}

.card-body1{
  width: 18rem;
  margin-left: 512px;
}

.result-card {
  border-left: 5px solid #007bff;
}

.card-title {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.circular-gauge {
  margin: auto;
}

.badge-success {
  background-color: #28a745;
}

.badge-primary {
  background-color: #007bff;
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}

@keyframes fillText {
  from {
    background-position: 100%;
  }
  to {
    background-position: 0%;
  }
}

.animated-title {
  font-size: 2em;
  font-weight: bold;
  background-image: linear-gradient(to right, #3498db, #9b59b6, #e74c3c, #f1c40f, #2ecc71);
  background-size: 200% auto;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: fillText 5s linear forwards;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animated-textarea {
  transform-origin: center;
  animation: scaleIn 0.3s ease-out;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

.animated-container {
  animation: flash 1s ease-in-out;
  transition: box-shadow 0.3s ease;
}

.animated-container:hover {
  box-shadow: none;
}

.logo {
  width: 250px; 
  height: auto;
}

.card-background {
  position: relative;
  width: 100%;
  min-height: 1000px; 
  background-image: url('../public/bg3.png'); 
  background-size: cover;
  background-position: center;
  border-radius: 10px;

}

.card-background .card-sm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; 
  max-width: 600px; 
  background: rgba(255, 255, 255, 0.8); 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-download {
  background-color: Lime;
  color: white; 
  border: none; 

  &:hover {
    background-color: purple;
    color: white;
  }

  &:focus {
    background-color: purple;
    color: white;
  }
}

.btn-pill {
  border-radius: 50px; 
}

#save-as-dropdown .dropdown-toggle {
  color: white !important; 
}

#save-as-dropdown .dropdown-toggle:hover, 
#save-as-dropdown .dropdown-toggle:focus {
  background-color: purple;
  color: white !important; 
}

.message-box {
  background-color: #d1ecf1; 
  border-color: #bee5eb; 
  color: #0c5460; 
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.core-web-vitals h5 {
  margin-top: 1.5rem;
  font-family: 'Arial', sans-serif; 
  font-weight: bold;
}

.metrics-container {
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap; 
}

.metrics-box {
  flex: 1; 
  margin: 0 1rem; 
  padding: 1rem; 
  border: 1px solid #ddd; 
  border-radius: 8px; 
  background-color: #f9f9f9; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  min-width: 250px; 
}

.metrics-box h7 {
  margin-bottom: 0.5rem; 
  color: #333; 
  font-weight: bold; 
}

.metrics-box ul {
  list-style: none; 
  padding: 0; 
}

.metrics-box li {
  margin: 0.5rem 0; 
  color: #555; 
  font-size: 0.9rem; 
  font-weight: bold;
}

.badge-info {
  margin-left: 10px;
  padding: 2px 5px;
  background-color: #17a2b8;
  color: white;
  border-radius: 3px;
}
